exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacoes-de-historias-index-js": () => import("./../../../src/pages/contacoes-de-historias/index.js" /* webpackChunkName: "component---src-pages-contacoes-de-historias-index-js" */),
  "component---src-pages-espetaculos-narrativos-index-js": () => import("./../../../src/pages/espetaculos-narrativos/index.js" /* webpackChunkName: "component---src-pages-espetaculos-narrativos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intervencoes-literarias-index-js": () => import("./../../../src/pages/intervencoes-literarias/index.js" /* webpackChunkName: "component---src-pages-intervencoes-literarias-index-js" */),
  "component---src-pages-oficinas-index-js": () => import("./../../../src/pages/oficinas/index.js" /* webpackChunkName: "component---src-pages-oficinas-index-js" */),
  "component---src-pages-rodas-de-leituras-index-js": () => import("./../../../src/pages/rodas-de-leituras/index.js" /* webpackChunkName: "component---src-pages-rodas-de-leituras-index-js" */)
}

